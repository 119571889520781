import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import "@fontsource/nanum-gothic"

const LinkText = styled(AnchorLink)`
  font-family: "Nanum Gothic";
  font-size: 1rem;
  margin: 10px 0;
  text-decoration: none;
  color: white;
`;

const NavMenu = styled("div")`
  width: 100vw;
  z-index: 45;
  position: fixed;
  top: 100px;
  left: 0;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  background: #48c2c5;
  transition: height 0.5s ease;
  @media (max-width: 950px) {
    display: flex;
  }
`;

const MobileNav = ({ height, setHeight, setMobileNavVisible }) => {
  return (
    <NavMenu
      css={css`
        height: ${height};
      `}
    >
      <LinkText
        to="/about"
        onClick={() => {
          setHeight("0px");
          setMobileNavVisible(false);
        }}
        onKeyDown={() => {
          setHeight("0px");
          setMobileNavVisible(false);
        }}
      >
        About
      </LinkText>
      <LinkText
        to="/services"
        onClick={() => {
          setHeight("0px");
          setMobileNavVisible(false);
        }}
        onKeyDown={() => {
          setHeight("0px");
          setMobileNavVisible(false);
        }}
      >
        Services
      </LinkText>
      <LinkText
        to="/#contact"
        stripHash
        onClick={() => {
          setHeight("0px");
          setMobileNavVisible(false);
        }}
        onKeyDown={() => {
          setHeight("0px");
          setMobileNavVisible(false);
        }}
      >
        Contact
      </LinkText>
    </NavMenu>
  );
};

export default MobileNav;
