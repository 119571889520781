import React from "react";
import Image from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { css } from "@emotion/core";
import { BlurbText, GreenInfoText } from "./styled-components";

const Footer = () => {
  const footerImages = useStaticQuery(graphql`
    query {
      icons: allFile(filter: { relativeDirectory: { eq: "footer-icons" } }) {
        nodes {
          sharp: childImageSharp {
            fixed(height: 40, width: 40) {
              ...GatsbyImageSharpFixed_withWebp
              originalName
            }
          }
          id
        }
      }
      logo: file(relativePath: { eq: "F-energy-logo-white.png" }) {
        image: childImageSharp {
          fixed(height: 125) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);
  const linkMap = {
    instagram: "/",
    facebook: "/",
    twitter: "/",
  };

  return (
    <>
      <div
        css={css`
          width: 100%;
          height: auto;
          padding: 75px 150px;
          background-color: #46c39f;
          margin-top: 0;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          @media (max-width: 950px) {
            padding: 75px 25px;
          }
        `}
      >
        <Image
          fixed={footerImages.logo.image.fixed}
          alt="Formation Energy Logo"
        />
        <br />
        <BlurbText color={"white"}>
          Formation Energy is a Woman Owned business that develops distributed
          and utility-scale solar projects in the PJM region. We have decades of
          experience in Greenfield and Brownfield project development and are
          driven by a passion to help benefit communities and the planet.
        </BlurbText>
      </div>
      <div
        css={css`
          width: 100%;
          height: 80px;
          padding: 20px 25px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media (max-width: 950px) {
            padding: 20px 10px;
          }
        `}
      >
        <GreenInfoText>2021 Formation Energy</GreenInfoText>
        <div
          css={css`
            display: flex;
          `}
        >
          {footerImages.icons.nodes.map((img) => (
            <a
              href={linkMap[`${img.sharp.fixed.originalName.split(".")[0]}`]}
              //target="_blank"
              rel="noreferrer"
              key={img.id}
            >
              <Image
                fadeIn={false}
                loading="eager"
                css={css`
                  margin: 0 5px;
                  border-radius: 100%;
                  &:hover {
                    box-shadow: 0px 0px 5px #48c2c5;
                  }
                `}
                fixed={img.sharp.fixed}
                alt={`Link to ${img.sharp.fixed.originalName.split(".")[0]}}`}
              />
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default React.memo(Footer);
