import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export const TitleText = styled("p")`
  font-size: 1.75rem;
  margin: 5px 0;
  @media (max-width: 950px) {
    font-size: 1.5rem;
  }
`;

export const BlurbText = styled("p")`
  font-size: ${(props) => (props.size ? props.size : "1.25rem")};
  color: ${(props) => (props.color ? props.color : "#607272")};
  margin-bottom: ${(props) => (props.margin ? "20px" : "")};
  @media (max-width: 950px) {
    font-size: ${(props) =>
      props.size
        ? props.size === "1.5rem"
          ? "1.25rem"
          : props.size === ".85rem"
          ? ".85rem"
          : "1rem"
        : "1rem"};
  }
`;

export const GreenInfoText = styled("p")`
  font-size: 0.85rem;
  color: #46c39f;
`;

export const GreenLinkText = styled(AnchorLink)`
  font-family: "Nanum Gothic";
  font-size: 0.85rem;
  text-decoration: none;
  color: #46c39f;
`;

export const WhiteLinkText = styled(AnchorLink)`
  font-family: "Nanum Gothic";
  font-size: 0.85rem;
  text-decoration: none;
  color: white;
`;

export const TextImageWrapper = styled("div")`
  display: flex;
  width: 80%;
  min-width: 700px;
  margin: 0 auto;
  justify-content: space-evenly;
  align-items: center;
  z-index: 10;
  @media (max-width: 1024px) {
    width: 90vw;
    min-width: 100px;
    flex-direction: ${(props) => (props.reverse ? "column-reverse" : "column")};
  }
`;

const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0) rotate(45deg);
    -webkit-transform: translate3d(0,0,0) rotate(45deg);
  }

  40%, 43% {
    transform: translate3d(0, -15px, 0) rotate(45deg);
    -webkit-transform: translate3d(0, -15px, 0) rotate(45deg);
  }

  70% {
    transform: translate3d(0, -10px, 0) rotate(45deg);
    -webikit-transform: translate3d(0, -10px, 0) rotate(45deg);
  }

  90% {
    transform: translate3d(0,-4px,0) rotate(45deg);
    -webkit-transform: translate3d(0,-4px,0) rotate(45deg);
  }
`;

const rotateToUp = keyframes`
0% {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

100% {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
`;

export const UpArrow = styled("i")`
  border: solid #48c2c5;
  border-width: 0 10px 10px 0;
  margin: 0 auto;
  display: inline-block;
  padding: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  animation: ${rotateToUp} 1s ease 1 forwards;
  :hover {
    cursor: pointer;
  }
`;

export const DownArrow = styled("i")`
  border: solid #48c2c5;
  border-width: 0 10px 10px 0;
  margin: 0 auto;
  display: inline-block;
  padding: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  animation: ${bounce} 2s ease infinite;
  :hover {
    cursor: pointer;
  }
`;
