import React, { useState } from "react";
import Image from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import "@fontsource/nanum-gothic"

import { DownArrow, UpArrow } from "./styled-components";
import MobileNav from "./mobile-nav";

const NavLink = styled(AnchorLink)`
  color: #666766;
  font-family: "Nanum Gothic";
  font-size: 1rem;
  font-weight: ${(props) => props.fontWeight || "normal"};
  line-height: 1;
  margin: 0 0.5rem 0 0;
  padding: 0.25rem;
  text-decoration: none;
  &.current-page {
    color: 2px solid #48c2c5;
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

const Header = () => {
  const logo = useStaticQuery(graphql`
    query Logo {
      file(relativePath: { eq: "logo-FE.png" }) {
        image: childImageSharp {
          fixed(height: 125) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const [mobileNavVisible, setMobileNavVisible] = useState(false);
  const [height, setHeight] = useState("0px");

  return (
    <header
      css={css`
        z-index: 50;
        position: fixed;
        top: 0;
        background-color: white;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        box-shadow: 0 8px 6px -6px #607272;
        margin-bottom: 75px;
        @media (max-width: 950px) {
          justify-content: space-between;
          margin-bottom: 0px;
        }
      `}
    >
      <div
        css={css`
          width: 75%;
          min-width: 600px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media (max-width: 950px) {
            min-width: 100px;
            width: 100%;
          }
        `}
      >
        <AnchorLink to="/">
          <Image
            fadeIn={false}
            loading="eager"
            fixed={logo.file.image.fixed}
            alt="Formation Energy Logo"
          />
        </AnchorLink>
        <nav
          css={css`
            display: flex;
            align-items: center;
            @media (max-width: 950px) {
              display: none;
            }
          `}
        >
          <NavLink to="/about" title="ABOUT" activeClassName="current-page" />
          <NavLink
            to="/services"
            title="SERVICES"
            activeClassName="current-page"
          />
          <NavLink to="/#contact" stripHash title="CONTACT" />
        </nav>
        <div
          css={css`
            display: none;
            @media (max-width: 950px) {
              display: block;
              margin-right: 25px;
            }
          `}
        >
          {!mobileNavVisible && (
            <DownArrow
              onClick={() => {
                setMobileNavVisible(true);
                setHeight(height === "0px" ? "Calc(100vh - 150px)" : "0px");
              }}
            />
          )}
          {mobileNavVisible && (
            <UpArrow
              onClick={() => {
                setMobileNavVisible(false);
                setHeight(height === "0px" ? "Calc(100vh - 150px)" : "0px");
              }}
            />
          )}
          <MobileNav
            height={height}
            setHeight={setHeight}
            setMobileNavVisible={setMobileNavVisible}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
